const apiUrl = 'https://elfduck.xyz/api'

const checkServerResponse = (res) => {
    if (res.ok) {
        // console.log(res.json)
        return res.json()
    }
    return Promise.reject(res);
};

export const WheelGet = (name, token) => {
    console.log(name.name)
    return fetch(`${apiUrl}/wheel/${name.name}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then((res) => checkServerResponse(res))
};

export const prizesGet = (wheel_id, token) => {
    console.log(wheel_id)
    return fetch(`${apiUrl}/prizes/${wheel_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then((res) => checkServerResponse(res))
};

export const spinAddItem = (user_id, wheel_id, prize_id, token) => {
    return fetch(`${apiUrl}/spin/add`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            user_id,
            wheel_id,
            prize_id
        })
    }).then((res) => checkServerResponse(res))
};


export const sendPrizeInfo = (user, prize_id, token) => {
    return fetch(`${apiUrl}/bot/sendPrizeInfo`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            user,
            prize_id
        })
    }).then((res) => checkServerResponse(res))
};