import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";

import marker from './marker.png';  // Импорт изображений
import logo from './logo.jpg';  // Импорт изображений

import * as api from "../../utils/api.js"

const tg = window.Telegram.WebApp;

const Wheel = (name) => {

  const [wheelData, setWheelData] = useState(null);
  const [isLoading, setLoading] = useState(true)
  const [sectors, setSectors] = useState({})
  const [hasSpun, setHasSpun] = useState(false);
  const [showDisplay, setShowDisplay] = useState(false); // Для отображения приза

  const zoneSize = 360 / Object.keys(sectors).length; // Размер зоны в градусах
  
  useEffect(() => {
    const fetchWheelData = async () => {
      const token = localStorage.getItem("token");
      const wheel = await api.WheelGet(name, token);

      const prizes = await api.prizesGet(wheel.id, token)
      console.log(prizes)

      const prizesList = {}
      for (const prize of prizes) {
        prizesList[prize.id] = prize.name
      }

      console.log(prizesList)

      setSectors(prizesList)

      setWheelData(wheel);
      setLoading(false); // Data has been fetched
    };

    fetchWheelData();
  }, [name]); // Run when name changes

  console.log(wheelData);
  

const [deg, setDeg] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [prize, setPrize] = useState("-");
  const canvasRef = useRef(null);

  const param = useParams();
  
  const drawWheel = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const sectorIds = Object.keys(sectors);
    const numSectors = sectorIds.length;
    const sectorAngle = 2 * Math.PI / numSectors;
    const radius = canvas.width / 2;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    let i = 0
    const colors = [`hsl(6, 70%, 50%)`, `hsl(6, 100%, 100%)`]

    sectorIds.forEach((sectorId, index) => {
      const startAngle = index * sectorAngle - Math.PI / 2; // Start at 12 o'clock
      const endAngle = startAngle + sectorAngle;

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.closePath();
      ctx.fillStyle = colors[i%2];
      ctx.fill();
      ctx.stroke();
      i ++;

      // Draw sector text
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(startAngle + sectorAngle / 2);
      ctx.textAlign = 'right';
      ctx.fillStyle = '#0f0200';
      ctx.font = 'bold 14px sans-serif';
      ctx.fillText(sectors[sectorId], radius - 10, 10);
      ctx.restore();
    });

      //  // Добавляем желтый ободок с лампочками
      //  const lampRadius = radius - 10;
      //  // Радиус лампочек
      //  const numLamps = 24;
      //  // Количество лампочек
      //  const lampAngle = 2 * Math.PI / numLamps;

      //  ctx.beginPath();
      //  ctx.arc(centerX, centerY, lampRadius, 0, 2 * Math.PI); // Ободок
      //  ctx.strokeStyle = 'yellow';
      //  ctx.lineWidth = 10;
      //  ctx.stroke();
   
      //  for (let j = 0; j < numLamps; j++) {
      //    const lampX = centerX + lampRadius * Math.cos(j * lampAngle);
      //    const lampY = centerY + lampRadius * Math.sin(j * lampAngle);
   
      //    ctx.beginPath();
      //    ctx.arc(lampX, lampY, 5, 0, 2 * Math.PI); // Лампочка
      //    ctx.fillStyle = 'yellow';
      //    ctx.fill();
      //    ctx.strokeStyle = 'orange';
      //    ctx.stroke();
      //  }

    // Draw arrow
    ctx.beginPath();
    ctx.moveTo(centerX - 10, centerY - radius - 20);
    ctx.lineTo(centerX + 10, centerY - radius - 20);
    ctx.lineTo(centerX, centerY - radius - 40);
    ctx.closePath();
    ctx.fillStyle = 'red';
    ctx.fill();
  };

  const handleSpin = () => {
    if (isSpinning) return;  // Предотвращаем повторное нажатие кнопки

    setPrize("-");
    setIsSpinning(true);
    setHasSpun(true);  // Устанавливаем состояние, что кнопка была нажата

    // Генерация случайного угла вращения между 5000 и 10000 градусами
    const newDeg = Math.floor(5000 + Math.random() * 5000);
    setDeg(newDeg);

    const canvas = canvasRef.current;
    if (canvas) {
      // Устанавливаем CSS для плавного вращения
      canvas.style.transition = 'all 10s ease-out';
      canvas.style.transform = `rotate(${newDeg}deg)`;
      canvas.classList.add('blur');
    }
  };

  const handleTransitionEnd = () => {
    setIsSpinning(false);
    setShowDisplay(true); // Показываем приз

    const canvas = canvasRef.current;
    if (canvas) {
      canvas.classList.remove('blur');
      canvas.style.transition = 'none';

      // Вычисляем фактический угол после вращения
      const actualDeg = deg % 360;

      // Определяем приз
      const sectorIndex = Math.floor((360 - actualDeg) / zoneSize) % Object.keys(sectors).length;
      const sectorIds = Object.keys(sectors);
      setPrize(sectors[sectorIds[sectorIndex]]);

      api.spinAddItem(tg.initDataUnsafe.user.id, wheelData.id, sectorIds[sectorIndex])
      api.sendPrizeInfo(tg.initDataUnsafe.user, sectorIds[sectorIndex])

    }
  };

  useEffect(() => {
    
    if (!isLoading) {
      console.log(wheelData.is_open)
      if (wheelData.is_open === true)
        setTimeout(() => {
          drawWheel();
        }, 5);
    }
  }, [isLoading]);

  useEffect(() => {
    // Обновляем изображение колеса при изменении угла
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.style.transform = `rotate(${deg}deg)`;
    }
  }, [deg]);
  








    const [activeRange, setActiveRange] = useState(0);

    useEffect(() => {
        // Update the active range every 1.5 seconds to create a moving effect
        const intervalId = setInterval(() => {
            setActiveRange(prev => (prev + 1) % 16); // Move the range in a circular manner
        }, 250); // Adjust the interval time as needed

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    const lightStyle = (index) => {
        // Determine if the current light is within the active range
        const isActive = (index >= activeRange && index < activeRange + 10) || (index + 16 >= activeRange && index + 16 < activeRange + 10);
        
        return {
            position: 'absolute',
            top: `${50 - 42 * Math.cos((index * 22.5) * Math.PI / 180)}%`,
            left: `${50 + 42 * Math.sin((index * 22.5) * Math.PI / 180)}%`,
            width: '20px',
            height: '20px',
            background: 'radial-gradient(circle, #ffeb3b 40%, #ffca28 70%, #f57f17 100%)',
            borderRadius: '50%',
            boxShadow: isActive ? `
                0 0 10px 4px rgba(255, 235, 59, 0.6),
                inset 0 0 7px rgba(255, 193, 7, 0.8),
                0 0 3px rgba(0, 0, 0, 0.5)
            ` : 'none',
            transform: `translate(-50%, -50%)`,
            opacity: isActive ? 1 : 0.3,
            transition: 'opacity 0.2s ease-in-out', // Smooth transition
        };
    };


  return (
    <div id="name">
      {
        isLoading ? (
          <div><h1>LOAD</h1></div>
        ) : (
          wheelData.is_open === true ? (
            <div className="wheelContainer">
              <img className="marker" src={marker} alt="Marker" />

              <div className='centerLogo'><img src={logo} alt="" /></div>

              <div className='lightContainer'>
                  {Array.from({ length: 16 }).map((_, i) => (
                      <div key={i} style={lightStyle(i)}></div>
                  ))}
              </div>
              
              <canvas
                className={`wheel ${isSpinning ? 'blur' : ''}`}
                ref={canvasRef}
                width={400}
                height={400}
                onTransitionEnd={handleTransitionEnd}
              />
              

              
              {!hasSpun && (
                <div className="container">
                  <button className="spin-button" onClick={handleSpin}>
                    <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12 4V1L8 5l4 4V6c2.7 0 5.4 1 7.5 2.8l1.4-1.4C17.4 5.2 14.1 3 12 3zM4.8 5.2L3.4 6.6C1.1 8.9 0 12.2 0 15.5v1h2v-1c0-2.6 1.2-5.1 3.1-6.9l1.7 1.7zM22 15.5v1h2v-1c0-3.3-1.1-6.6-3.4-9.2l-1.4 1.4C21.4 9.4 22 12 22 15.5z"/>
                    </svg>
                    <span className="button-text">Крутить</span>
                  </button>
                </div>
              )}

              {showDisplay && (
                <div className="display">
                  <span>Вам выпало:</span>
                  <span className="prize-text">{prize}</span>
                </div>
              )} {/* Показываем приз, когда он определён */}

            </div>
          ) : (
            <div className='close-message'><h1>Сейчас колесо закрыто</h1></div>
          )
        )
      }
    </div>
  );
  
};

export default Wheel;
